import React, { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import {
  Avatar,
  Logo,
  IconFind,
  IconPlayHollow,
  IconHomeHollow,
  IconChalkboardTeacher,
  IconTelevisionSimple,
  IconPlusCircle,
  IconClockClockwise,
  IconDotsThreeVertical,
  IconBooks,
  Submenu,
} from 'components';

import { logoutHandler } from 'utils';
import { useFeatureToggle } from 'hooks';

import { main as theme } from 'themes';

import { SearchInput } from '../../SearchInput';
import { Notifications } from '../../Notifications';

import * as S from './HeaderTablet.styles';

interface HeaderTabletProps {
  refreshToken: string;
}

const HeaderTablet = React.memo(({ refreshToken }: HeaderTabletProps) => {
  const [showSeach, setShowSearch] = useState(false);
  const [showMainSubmenu, setShowMainSubmenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showSocialSubmenu, setShowSocialSubmenu] = useState(false);
  const router = useRouter();
  const activeHref = router.pathname;
  const inputRef = useRef(null);
  const submenuSocialRef = useRef(null);
  const submenuMainRef = useRef(null);
  const notificationsRef = useRef(null);

  const dispatch = useDispatch();
  const logOut = () => logoutHandler(dispatch);

  const handleOnClickNotifications = () => {
    setShowNotifications((prevState) => !prevState);
  };

  const handleClickOutside = (e: any) => {
    if (
      submenuSocialRef &&
      submenuSocialRef.current &&
      !submenuSocialRef.current.contains(e.target)
    ) {
      setShowSocialSubmenu(false);
    }
    if (
      submenuMainRef &&
      submenuMainRef.current &&
      !submenuMainRef.current.contains(e.target)
    ) {
      setShowMainSubmenu(false);
    }
    if (
      notificationsRef &&
      notificationsRef.current &&
      !notificationsRef.current.contains(e.target)
    ) {
      setShowNotifications(false);
    }
  };

  const handleOnClickSocial = () => {
    setShowSocialSubmenu((prevState) => !prevState);
  };

  const handleOnClickMain = () => {
    setShowMainSubmenu((prevState) => !prevState);
  };

  const SubmenuWithCertificates = () => (
    <Submenu
      isVisible={showSocialSubmenu}
      position={{ bottom: '-175px', right: '32px' }}
      width="262px"
      options={[
        {
          item: 'Perfil',
          action: () => {
            router.push('/perfil');
          },
        },
        {
          item: 'Meus Certificados',
          action: () => {
            router.push('/certificados');
          },
        },
        { item: 'Ajuda', href: 'https://meusucesso.com/faq/' },
        { item: 'Sair', action: logOut },
      ]}
      subMenuRef={submenuSocialRef}
      showSocial
      onClick={handleOnClickSocial}
    />
  );

  const SubmenuWithoutCertificates = () => (
    <Submenu
      isVisible={showSocialSubmenu}
      position={{ bottom: '-139px', right: '32px' }}
      width="262px"
      options={[
        {
          item: 'Perfil',
          action: () => {
            router.push('/perfil');
          },
        },
        { item: 'Ajuda', href: 'https://meusucesso.com/faq/' },
        { item: 'Sair', action: logOut },
      ]}
      subMenuRef={submenuSocialRef}
      showSocial
      onClick={handleOnClickSocial}
    />
  );

  const SubmenuToggle = useFeatureToggle({
    ComponentWhenEnabled: () => <SubmenuWithCertificates />,
    ComponentWhenDisabled: () => <SubmenuWithoutCertificates />,
    featureKeys: ['certificates'],
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <S.Navbar>
      <Link href="/home" className="ms-logo" id="header-logo">
        {/* @ts-ignore */}
        <Logo color={theme.colorPallete.white} />
      </Link>
      <S.MenuList>
        <S.MenuItem
          className={
            activeHref === '/home' ? 'menu-item__active' : 'menu-item'
          }>
          <Link href="/home">
            <IconHomeHollow />
          </Link>
        </S.MenuItem>

        <S.MenuItem
          className={
            activeHref === '/continue-assistindo'
              ? 'menu-item__active'
              : 'menu-item'
          }>
          <Link href="/continue-assistindo">
            <IconPlayHollow />
          </Link>
        </S.MenuItem>
        <S.MenuItem className="dropdown" ref={submenuMainRef}>
          <button className="three-dots" onClick={handleOnClickMain}>
            <IconDotsThreeVertical />
          </button>
          <Submenu
            isVisible={showMainSubmenu}
            position={{ bottom: '-160px', right: '-280px' }}
            width="300px"
            options={[
              {
                item: 'Estudos de Caso',
                action: () => {
                  router.push('/estudos-de-caso');
                },
                icon: <IconChalkboardTeacher />,
              },
              {
                item: 'Séries',
                action: () => {
                  router.push('/series');
                },
                icon: <IconTelevisionSimple />,
              },
              {
                item: 'Assistidos',
                action: () => {
                  router.push('/assistidos');
                },
                icon: <IconClockClockwise />,
              },
            ]}
            onClick={handleOnClickSocial}
          />
        </S.MenuItem>
      </S.MenuList>

      <S.NavbarRight>
        <S.SearchButton
          type="button"
          onClick={() => {
            setShowSearch(true);
            inputRef.current.focus();
          }}>
          <IconFind size="24px" color={theme.colorPallete.base[320]} />
        </S.SearchButton>
        <Notifications
          notificationsRef={notificationsRef}
          isVisible={showNotifications}
          onClick={handleOnClickNotifications}
        />
        <Avatar onClick={handleOnClickSocial} />
        <SubmenuToggle />
        <SearchInput
          searchState={showSeach}
          hideSearch={setShowSearch}
          refreshToken={refreshToken}
          reference={inputRef}
        />
      </S.NavbarRight>
    </S.Navbar>
  );
});

export default HeaderTablet;
export { HeaderTablet };
