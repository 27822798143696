/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import {
  Text,
  Logo,
  IconFacebookOutline,
  IconInstagramOutline,
  IconYoutubeOutline,
} from '@components';
import { main as theme } from '@themes';

import { styles } from './Footer.styles';

const Wrapper = styled.footer`
  ${styles}
`;

const Footer = React.memo(() => {
  return (
    <Wrapper className="footer">
      <div className="footer__content">
        <div className="info__logo">
          <Logo size="150px" color={theme.colorPallete.white} />
        </div>

        <div className="content__info footer-grid">
          <div className="info__sitemap">
            <Text
              as="h5"
              weight="bold"
              color={theme.colorPallete.white}
              size="1.6rem"
              dsize="1.6rem"
              height="2rem"
              dheight="2rem">
              Conteúdo
            </Text>
            <ul>
              <li>
                <Link
                  href="/estudos-de-caso"
                  passHref
                  target="_blank"
                  id="footer__links__estudo-de-caso">
                  <Text
                    color={theme.colorPallete.base[300]}
                    size="1.4rem"
                    dsize="1.4rem">
                    Estudos de Caso
                  </Text>
                </Link>
              </li>
              <li>
                <Link
                  href="/series"
                  passHref
                  target="_blank"
                  id="footer__links__series">
                  <Text
                    color={theme.colorPallete.base[300]}
                    size="1.4rem"
                    dsize="1.4rem">
                    Séries
                  </Text>
                </Link>
              </li>
            </ul>
          </div>
          <div className="info__blog">
            <Text
              as="h5"
              weight="bold"
              color={theme.colorPallete.white}
              size="1.6rem"
              dsize="1.6rem"
              height="2rem"
              dheight="2rem">
              Publicações
            </Text>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://meusucesso.com/artigos/"
                  id="footer__links__artigos">
                  <Text
                    color={theme.colorPallete.base[300]}
                    size="1.4rem"
                    dsize="1.4rem">
                    Artigos
                  </Text>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://meusucesso.com/noticias/"
                  id="footer__links__noticias">
                  <Text
                    color={theme.colorPallete.base[300]}
                    size="1.4rem"
                    dsize="1.4rem">
                    Notícias
                  </Text>
                </a>
              </li>
            </ul>
          </div>
          <div className="info__institution">
            <Text
              as="h5"
              weight="bold"
              color={theme.colorPallete.white}
              size="1.6rem"
              dsize="1.6rem"
              height="2rem"
              dheight="2rem">
              Institucional
            </Text>

            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://meusucesso.com/politica-de-privacidade/"
                  id="footer__links__politica-de-privacidade">
                  <Text
                    color={theme.colorPallete.base[300]}
                    size="1.4rem"
                    dsize="1.4rem">
                    Política de privacidade
                  </Text>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://meusucesso.com/termos-de-servico/"
                  id="footer__links__termos-de-servico">
                  <Text
                    color={theme.colorPallete.base[300]}
                    size="1.4rem"
                    dsize="1.4rem">
                    Termos de Uso
                  </Text>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://meusucesso.com/faq/"
                  id="footer__links__faq">
                  <Text
                    color={theme.colorPallete.base[300]}
                    size="1.4rem"
                    dsize="1.4rem">
                    Ajuda
                  </Text>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="content__buttons footer-grid">
          <div className="buttons__download">
            <Text
              as="h5"
              weight="bold"
              color={theme.colorPallete.white}
              size="1.6rem"
              dsize="1.6rem"
              height="2rem"
              dheight="2rem">
              Baixe nosso App
            </Text>
            <a
              href="https://play.google.com/store/apps/details?id=com.meusucesso"
              target="_blank"
              id="footer__button__android">
              <img
                src="/assets/images/google-play.svg"
                alt="Baixe na Google Play"
              />
            </a>
            <a
              href="https://apps.apple.com/br/app/meusucesso/id1073147098?l=en"
              target="_blank"
              id="footer__button__ios">
              <img
                src="/assets/images/app-store.svg"
                alt="Baixe na App Store"
              />
            </a>
          </div>
          <div className="buttons__social">
            <Text
              as="h5"
              weight="bold"
              color={theme.colorPallete.white}
              size="1.6rem"
              dsize="1.6rem"
              height="2rem"
              dheight="2rem">
              Siga a gente
            </Text>
            <span>
              <a
                href="https://www.instagram.com/meusucesso/"
                target="_blank"
                id="footer__link__instagram">
                <IconInstagramOutline />
              </a>

              <a
                href="https://www.facebook.com/canalmeusucesso/"
                target="_blank"
                id="footer__link__facebook">
                <IconFacebookOutline />
              </a>

              <a
                href="https://www.youtube.com/user/meusucessocanal"
                target="_blank"
                id="footer__link__youtube">
                <IconYoutubeOutline />
              </a>
            </span>
          </div>
        </div>
      </div>
      <Text as="p" color={theme.colorPallete.base[300]} dsize="1.3rem">
        meuSucesso.com© Copyright 2020. powered by meuSucesso.com
      </Text>
    </Wrapper>
  );
});

export default Footer;
export { Footer };
